import React from 'react';
import { 
    Datagrid,
    List,
    SimpleForm,
    Create, 
    Edit,  
    TextField,
    NumberField, 
    TextInput,  
    Filter,
    SearchInput,
    NumberInput,
    SimpleList
} from 'react-admin'; 

import { Box, useMediaQuery } from '@mui/material';
import BookIcon from '@mui/icons-material/Book'; 


const MeterModelFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);


const Title = ({ record }) => {
    return <span>/{record ? `${record.name}` : ''}</span>;
};


const MeterModelForm = props => (
    <SimpleForm {...props} > 
        <Box display={{ xs: 'block', sm: 'flex' }} fullWidth>
            <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
            >
                <TextInput source="name" label='Название' fullWidth/> 
                <TextInput source="unit_of_measurement" label="Единица измерения"  fullWidth/>
                <NumberInput source="lifetime_years" step={1} label="Срок службы(в годах)" fullWidth/>
            </Box>
            <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
            >
                <NumberInput source="proportionality_factor" label="Коэффициент пропорциональности" fullWidth/> 
                <TextInput source="description" label="Описание" fullWidth/>
                <NumberInput source="warranty_years" step={1} label="Гарантийный срок(в годах)" fullWidth/>
            </Box>
        </Box>
    </SimpleForm>
)


export const MeterModelEdit = (props) => (
    <Edit title={<Title/>}   {...props}>
        <MeterModelForm/>
    </Edit>
);
 

const MeterModelList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}
            filters={<MeterModelFilter/>}>
            { isSmall ? ( 
                <SimpleList
                    primaryText={record => `Название: ${record.name}`}
                    secondaryText={record => (
                        `Коэффициент пропорциональности: ${record.proportionality_factor}, 
                            Ед.изм.: ${record.unit_of_measurement}`
                        )}
                    linkType={record => "edit"} 
                    />
            ) : (
                <Datagrid  
                    rowClick={() => ('edit')}> 
                    <TextField source="name" label='Название'/> 
                    <TextField source="description" label="Описание"/>
                    <NumberField source="proportionality_factor" label="Коэффициент пропорциональности"/> 
                    <TextField source="unit_of_measurement" label="Единица измерения" />
                </Datagrid>
            ) }
        </List>
    );
}

  
const MeterModelCreate = (props) => { 
    return (
        <Create title="Добавление измерителя" {...props}>
            <MeterModelForm/>
        </Create>
);}
 

export default {
    list: MeterModelList,
    create: MeterModelCreate,
    edit: MeterModelEdit,  
    icon: BookIcon,
};
